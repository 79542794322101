// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FileUploadModal_dropzone__3dz6t {\n}\n\n.FileUploadModal_fileListName__2Ed9P {\n    cursor: pointer;\n}\n\n.FileUploadModal_fileList__1eD9m {\n    margin: 20px;\n}\n\n.FileUploadModal_fileInputContainer__3qoLq {\n    margin: 15px 0;\n}", ""]);
// Exports
exports.locals = {
	"dropzone": "FileUploadModal_dropzone__3dz6t",
	"fileListName": "FileUploadModal_fileListName__2Ed9P",
	"fileList": "FileUploadModal_fileList__1eD9m",
	"fileInputContainer": "FileUploadModal_fileInputContainer__3qoLq"
};
module.exports = exports;
