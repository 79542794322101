// Duplicate and remove the underscore from this file and add your google oauth ID and API endpoint below before building

export default {
  googleClientId: '645194563989-lk4in2ei55nahe3uh8mttib4t3ahka93.apps.googleusercontent.com', // The OAUTH client ID for your file browser
  APIEndpoint: 'https://asia-northeast3-digitaltwin-production-f19a.cloudfunctions.net/fileApi', // The URL to the cloud function
  CDN_URL: 'https://dtp-cloud-stroage.35.241.18.104.nip.io/', // The base URL to your CDN or bucket. This might be a custom subdomain or https://bucket-name.storage.googleapis.com/ if you don't have a CDN.
  // BucketUrl: 'https://storage.googleapis.com/digitaltwin_bucket/', // This is used to bypass the cache on your CDN. ONLY replace the YOUR-BUCKET-NAME part with the name of your bucket.
  // CDN_URL: 'https://digitaltwin_bucket.storage.googleapis.com', // The base URL to your CDN or bucket. This might be a custom subdomain or https://bucket-name.storage.googleapis.com/ if you don't have a CDN.
  BucketUrl: 'https://digitaltwin_bucket.storage.googleapis.com/', // This is used to bypass the cache on your CDN. ONLY replace the YOUR-BUCKET-NAME part with the name of your bucket.
  appName: 'DTP CDN File Manager' // The name that appears at the top of the app menu.
}
